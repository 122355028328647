<!-- <template>
  <div class="map-container">
    <div ref="mapChart" style="width: 100%; height: 80vh;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
require('echarts/extension/bmap/bmap'); // 引入百度地图扩展
export default {

  data() {
    return {
      trajectoryData: [
        { // 轨迹1：北京->郑州->武汉->长沙
          id: 1,
          points: [
            [116.404, 39.915], // 北京
            [113.665, 34.757], // 郑州
            [114.305, 30.593], // 武汉
            [112.938, 28.228]  // 长沙
          ],
        },
        { // 轨迹2：上海->杭州->南昌->广州（曲线路径）
          id: 2,
          points: [
            [121.473, 31.23],  // 上海
            [120.155, 30.274], // 杭州
            [115.858, 28.683], // 南昌
            [113.264, 23.129]  // 广州
          ],
        },
        { // 轨迹3：成都->重庆->贵阳复杂路径
          id: 3,
          points: [
            [104.066, 30.572], // 成都
            [104.066, 30.572], // 保持点（演示停留）
            [106.551, 29.563], // 重庆
            [106.551, 29.563], // 重复点（演示数据处理）
            [106.628, 26.647], // 贵阳
            [106.700, 26.600]  // 终点偏移
          ],
        }
      ] // 从API获取轨迹数据
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    // 数据清洗和转换
    processTrackData(rawData) {
      return rawData.map(track => ({
        coords: this.removeDuplicatePoints(track.points),
        lineStyle: {
          color: '#00BFFF',
          width: 2,
          opacity: 0.7,
          curveness: 0.3 // 路径弯曲度
        }
      }))
    },

    // 去除连续重复点
    removeDuplicatePoints(points) {
      return points.filter((point, index) => {
        if (index === 0) return true
        const prev = points[index - 1]
        return !(point[0] === prev[0] && point[1] === prev[1])
      })
    },
    initMap() {
      const chartDom = this.$refs.mapChart
      const myChart = echarts.init(chartDom)

      myChart.setOption({
        bmap: {
          center: [116.46, 39.92], // 初始中心点
          zoom: 8,
          roam: true, // 允许缩放和拖拽
          mapStyle: {
            styleJson: [{
              "featureType": "highway",
              "elementType": "all",
              "stylers": { "visibility": "off" }
            }]
          }
        },
        series: [{
          type: 'lines',
          coordinateSystem: 'bmap',
          data: this.processTrackData(this.trajectoryData),
          polyline: true,
          large: true,  // 启用大数据模式
          largeThreshold: 2000, // 数据量>2000时启用优化
          progressiveChunkMode: 'mod', // 分片渲染
          lineStyle: {
            color: '#55a8ff',
            width: 1,
            opacity: 1
          },
          effect: {
            show: true,
            period: 4, // 动画速度
            trailLength: 0.3, // 尾迹长度
            symbol: 'arrow', // 轨迹箭头
            symbolSize: 8
          }
        }],
        tooltip: {
          trigger: 'item',
          formatter: params => {
            const points = params.data.coords
            return `
              <div style="padding:5px">
                <div>轨迹长度：${points.length}个节点</div>
                <div>起点：${points[0]}</div>
                <div>终点：${points[points.length-1]}</div>
              </div>
            `
          }
        }
      });

      // 关联百度地图实例
      const bmap = myChart.getModel().getComponent('bmap').getBMap();
      bmap.setMapType(BMAP_HYBRID_MAP);

      // 轨迹点击事件
      myChart.on('click', { seriesIndex: 0 }, (params) => {
        const track = this.multiTrackData.find(t => t.id === params.dataIndex + 1)
        console.log('完整轨迹数据:', track.points)
      })
    }
  }
};


</script>

<style scoped>
.map-container {
  margin: 20px;
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
}
</style> -->

<template>
  <div class="map-container">
    <div ref="chart" class="chart"></div>
    <div v-if="loading" class="loading-overlay">
      <div class="loader"></div>
      <div class="loading-text">{{ loadingText }}</div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'SatelliteMap',
  data() {
    return {
      chart: null,
      loading: true,
      loadingText: '地图初始化中...',
      tdtKey: '56e725cb281c9c8af8a53ddad62d3b82', // 替换为你的天地图密钥
      trajectories: [
        {
          name: '无人机航线',
          coords: [
            [116.297428, 39.80923],
            [116.307428, 39.81923],
            [116.317428, 39.82923],
            [116.327428, 39.83923]
          ],
          color: '#FF0000',
          width: 3
        },
        {
          name: '物流路线',
          coords: [
            [116.397428, 39.90923],
            [116.407428, 39.91923],
            [116.417428, 39.92923]
          ],
          color: '#00FF00',
          width: 2
        }
      ]
    };
  },
  mounted() {
    this.initMap();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    initMap() {
      this.chart = echarts.init(this.$refs.chart);
      this.registerTianDiSatelliteMap();
      this.drawTrajectories();
    },

    // 注册天地图卫星地图
    registerTianDiSatelliteMap() {
      const subdomains = ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'];

      // 卫星影像图层
      echarts.registerMap('TIANDITU_SATELLITE', {
        getTileUrl: (z, x, y) => {
          const subdomain = subdomains[(x + y) % subdomains.length];
          return [
            `https://${subdomain}.tianditu.gov.cn/img_w/wmts`,
            `SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0`,
            `LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles`,
            `TILEMATRIX=${z}&TILEROW=${y}&TILECOL=${x}`,
            `tk=${this.tdtKey}`
          ].join('&');
        },
        coordinates: {
          projection: {
            project: ([lng, lat]) => [lng, lat],
            unproject: ([x, y]) => [x, y]
          }
        },
        center: [116.397428, 39.90923],
        zoom: 12
      });

      // 卫星注记图层
      echarts.registerMap('TIANDITU_SATELLITE_ANNOTATION', {
        getTileUrl: (z, x, y) => {
          const subdomain = subdomains[(x + y) % subdomains.length];
          return [
            `https://${subdomain}.tianditu.gov.cn/cia_w/wmts`,
            `SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0`,
            `LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles`,
            `TILEMATRIX=${z}&TILEROW=${y}&TILECOL=${x}`,
            `tk=${this.tdtKey}`
          ].join('&');
        }
      });
    },

    // 绘制轨迹
    drawTrajectories() {
      const option = {
        animation: false,
        geo: [
          // 卫星影像底图
          {
            map: 'TIANDITU_SATELLITE',
            roam: true,
            zoom: 12,
            center: [116.397428, 39.90923],
            itemStyle: {
              areaColor: 'transparent'
            }
          },
          // 卫星注记图层
          {
            map: 'TIANDITU_SATELLITE_ANNOTATION',
            roam: true,
            zlevel: 1
          }
        ],
        series: this.processTrajectories()
      };

      this.chart.setOption(option);
      this.loading = false;
    },

    // 处理轨迹数据
    processTrajectories() {
      return this.trajectories.map((traj, index) => ({
        type: 'lines',
        name: traj.name,
        polyline: true,
        coordinateSystem: 'geo',
        zlevel: 2 + index,
        effect: {
          show: true,
          period: 4,
          trailLength: 0.3,
          symbol: 'arrow',
          symbolSize: 8
        },
        lineStyle: {
          color: traj.color,
          width: traj.width,
          type: 'solid'
        },
        data: [{
          coords: this.simplifyTrajectory(traj.coords)
        }],
        progressive: 200,
        progressiveThreshold: 5000
      }));
    },

    // 轨迹抽稀算法
    simplifyTrajectory(coords, tolerance = 0.0002) {
      if (coords.length <= 2) return coords;

      let maxDistance = 0;
      let index = 0;
      const start = coords[0];
      const end = coords[coords.length - 1];

      for (let i = 1; i < coords.length - 1; i++) {
        const distance = this.calculateDistance(coords[i], start, end);
        if (distance > maxDistance) {
          maxDistance = distance;
          index = i;
        }
      }

      if (maxDistance > tolerance) {
        return [
          ...this.simplifyTrajectory(coords.slice(0, index + 1)),
          ...this.simplifyTrajectory(coords.slice(index))
        ].slice(0, -1);
      }
      return [start, end];
    },

    calculateDistance(point, lineStart, lineEnd) {
      const [x, y] = point;
      const [x1, y1] = lineStart;
      const [x2, y2] = lineEnd;

      const area = Math.abs(
        (y2 - y1) * x -
        (x2 - x1) * y +
        x2 * y1 -
        y2 * x1
      );
      const lineLength = Math.hypot(y2 - y1, x2 - x1);
      return lineLength ? area / lineLength : 0;
    },

    handleResize() {
      if (this.chart) {
        this.chart.resize();
      }
    }
  }
};
</script>

<style scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.chart {
  width: 100%;
  height: 100%;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  margin-top: 15px;
  color: #fff;
  font-size: 16px;
}
</style>




<!-- <template>
  <div id="map" style="width: 100%; height: 80vh;"></div>
</template>

<script>

import * as echarts from 'echarts';
export default {
  name: 'Map',
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      if (window.T) {
        const map = new T.Map('map');
        map.centerAndZoom(new T.LngLat(116.40769, 39.90403), 12); // 设置地图中心点和缩放级别
        map.addControl(new T.Control.Zoom()); // 添加缩放控件
        map.addControl(new T.Control.Scale()); // 添加比例尺控件
        map.addControl(new T.Control.OverviewMap()); // 添加鹰眼图控件

        console.log('地图初始化成功');
      } else {
        console.error('天地图API加载失败');
      }
    },
    initEcharts() {
      const chart = echarts.init(document.getElementById('map'));
      const option = {
        geo: {
          map: 'TIANDITU',
          roam: true,
          zoom: 12,
          center: [116.397428, 39.90923],
          itemStyle: {
            areaColor: 'transparent'
          }
        },
        series: this.trajectories.map(traj => ({
          type: 'lines',
          name: traj.name,
          polyline: true,
          coordinateSystem: 'geo',
          data: [{
            coords: this.simplify(traj.coords),
            lineStyle: {
              color: traj.color,
              width: traj.width,
              type: 'solid'
            },
            effect: {
              show: true,
              period: 6,
              trailLength: 0.3,
              symbol: 'arrow',
              symbolSize: 8
            }
          }],
          progressive: 200,
          progressiveThreshold: 5000
        }))
      }
      chart.setOption(option);
    }
  }
}
</script>

<style scoped>
#map {
  width: 100%;
  height: 100vh;
}
</style> -->